import React, { useContext } from 'react';
import SpecificProjects from '../../components/cms-modules/dynamicTeaser/components/specificProjects';
import ContributionDetail from '../../components/contributions/detail';
import Headline from '../../components/ui/headline';
import { useLocale } from '../../context/locale.context';
import { MicrocopyContext } from '../../context/microcopy.context';
import { getCopy, withMicrocopy } from '../../helpers/copy';
import { swapImageUrl } from '../../helpers/swapImageURI';
import Container from '../../modules/container';
import Layout from '../../modules/layout';
import * as styles from './styles.module.scss';
import { Helmet } from 'react-helmet';

const ContributionDetailTemplate = ({ contributionData }) => {
  const { locale } = useLocale();

  const relatedProjects = contributionData?.relatedEntries.map((item) =>
    item.id.toString()
  );

  const TranslatedContent = withMicrocopy(() => {
    const microcopy = useContext(MicrocopyContext);
    const headline = getCopy('html.relatedprojects', microcopy);

    return (
      <Layout
        title={contributionData.name}
        description={contributionData.description}
        slug={contributionData.slug}
      >
        <Helmet>
          <link
            rel="preload"
            href={swapImageUrl(contributionData?.primaryMedia)}
          />
        </Helmet>
        <div className={styles.relatedProjectsWrapper}>
          <Container fluid>
            <ContributionDetail data={contributionData} />
          </Container>
          <div className={styles.relatedProjectsWrapper}>
            <Container fluid>
              <Headline
                text={getCopy('html.relatedprojects', microcopy)}
                level="h3"
                type="h1"
                className={styles.headline}
              />
              {relatedProjects && (
                <SpecificProjects
                  headline={headline}
                  dataIds={relatedProjects}
                />
              )}
            </Container>
          </div>
        </div>
      </Layout>
    );
  }, locale);

  return <TranslatedContent />;
};

export default ContributionDetailTemplate;
