const apiUrl = new URL(process.env.GATSBY_API_ROOT);
export const swapImageUrl = (url) => {
  if (!url) {
    return '';
  }
  const imageUrl = new URL(url);

  imageUrl.host = apiUrl.host;
  return imageUrl.toString();
};
